import { LangDe } from "@/stores/interfaces/I18NLanguage";
import type I18NLanguage from "@/stores/interfaces/I18NLanguage";
import i18n from "@/i18n";
import type { ComputedRef } from "vue";

export function tByLang<T>(
  de: T,
  en: T,
  override: I18NLanguage | null = null,
): T {
  const locale =
    override ?? (i18n.global.locale as unknown as ComputedRef<string>).value;

  return locale === LangDe ? de : en;
}

export function tIsDe(override: I18NLanguage | null = null): boolean {
  const locale =
    override ?? (i18n.global.locale as unknown as ComputedRef<string>).value;

  return locale === LangDe;
}

export function langToString(lang: I18NLanguage): string {
  return lang === LangDe ? "de" : "en";
}
