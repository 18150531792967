import ApiClient from "@/api/infrastructure/ApiClient";

export default {
  async rebuildIndex(): Promise<boolean> {
    try {
      const response = await ApiClient.getJson<boolean>(
        "internals/rebuildIndex",
      );
      return response.data;
    } catch {
      return false;
    }
  },
};
