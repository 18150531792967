import type EntryDto from "@/dto/EntryDto";
import ApiClient from "@/api/infrastructure/ApiClient";
import type { UpdateTitleDto } from "@/dto/UpdateTitleDto";
import type { UpdateAddressDto } from "@/dto/UpdateAddressDto";
import type { UpdateTextDto } from "@/dto/UpdateTextDto";
import type { UpdateRepresentativeDto } from "@/dto/UpdateRepresentativeDto";
import type { UpdateLogoDto } from "@/dto/UpdateLogoDto";
import type { UpdateStatsDto } from "@/dto/UpdateStatsDto";
import type { UpdateImagesDto } from "@/dto/UpdateImagesDto";
import type { UpdateMissionsDto } from "@/dto/UpdateMissionsDto";
import type { UpdateCertificatesDto } from "@/dto/UpdateCertificatesDto";
import type { UpdateCooperationsDto } from "@/dto/UpdateCooperationsDto";
import type { UpdateSegmentsDto } from "@/dto/UpdateSegmentsDto";
import type { UpdateOrganisationTypeDto } from "@/dto/UpdateOrganisationTypeDto";
import type UpdateSocialMediaDto from "@/dto/UpdateSocialMediaDto";

export default {
  async get(slug: string): Promise<EntryDto> {
    const response = await ApiClient.getJson<EntryDto>(`entries/${slug}`);

    return response.data;
  },

  async getDraft(slug: string): Promise<EntryDto> {
    const response = await ApiClient.getJson<EntryDto>(`entries/${slug}/draft`);

    return response.data;
  },

  async createDraft(slug: string): Promise<EntryDto> {
    const createResponse = await ApiClient.post(`entries/${slug}/draft`, null);

    // Check for 201 Created status
    if (createResponse.original.status !== 201)
      throw new Error("Draft was not created");

    // retrieve location of created resource
    const draftLocation = createResponse.original.headers.get("Location");
    if (draftLocation === null || draftLocation.length === 0)
      throw new Error("Draft was not created");

    const draftResponse = await ApiClient.getJson<EntryDto>(draftLocation);

    return draftResponse.data;
  },

  async discardDraft(slug: string): Promise<void> {
    await ApiClient.delete(`entries/${slug}/draft`);
  },

  async submitForReview(slug: string): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/submit-for-review`, null);
  },

  async updateTitle(slug: string, dto: UpdateTitleDto): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/title`, dto);
  },

  async updateAddress(slug: string, dto: UpdateAddressDto): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/address`, dto);
  },

  async updateStats(slug: string, dto: UpdateStatsDto): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/stats`, dto);
  },

  async updateRepresentative(
    slug: string,
    dto: UpdateRepresentativeDto,
  ): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/representative`, dto);
  },

  async updateSocialMedia(
    slug: string,
    dto: UpdateSocialMediaDto,
  ): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/social-media`, dto);
  },

  async updateText(slug: string, dto: UpdateTextDto): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/text`, dto);
  },

  async updateLogo(slug: string, dto: UpdateLogoDto): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/logo`, dto);
  },

  async updateImages(slug: string, dto: UpdateImagesDto): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/images`, dto);
  },

  async updateMissions(slug: string, dto: UpdateMissionsDto): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/missions`, dto);
  },

  async updateCertificates(
    slug: string,
    dto: UpdateCertificatesDto,
  ): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/certificates`, dto);
  },

  async updateCooperations(
    slug: string,
    dto: UpdateCooperationsDto,
  ): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/cooperations`, dto);
  },

  async updateSegments(slug: string, dto: UpdateSegmentsDto): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/segments`, dto);
  },

  async updateOrganisationType(
    slug: string,
    dto: UpdateOrganisationTypeDto,
  ): Promise<void> {
    await ApiClient.patch(`entries/${slug}/draft/organisationType`, dto);
  },
};
