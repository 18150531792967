import type AdminDashboardDto from "@/dto/AdminDashboardDto";
import ApiClient from "@/api/infrastructure/ApiClient";
import type AccountDto from "@/dto/AccountDto";
import type EntryDiffDto from "@/dto/EntryDiffDto";
import type AdminReviewNoteDto from "@/dto/AdminReviewNoteDto";
import type EntryHistoryDto from "@/dto/EntryHistoryDto";

export default {
  async dashboard(): Promise<AdminDashboardDto> {
    const response =
      await ApiClient.getJson<AdminDashboardDto>("admin/dashboard");

    return response.data;
  },

  async getUserById(userId: number): Promise<AccountDto> {
    const response = await ApiClient.getJson<AccountDto>("admin/getUserById", {
      userId: userId.toFixed(0),
    });

    return response.data;
  },

  async getReviewEntryDiff(slug: string): Promise<EntryDiffDto> {
    const response = await ApiClient.getJson<EntryDiffDto>(
      "admin/getReviewEntryDiff",
      {
        slug: slug,
      },
    );

    return response.data;
  },

  async entryReviewAccept(review: AdminReviewNoteDto): Promise<void> {
    await ApiClient.post("admin/entryReviewAccept", review);
  },

  async entryReviewDeny(review: AdminReviewNoteDto): Promise<void> {
    await ApiClient.post("admin/entryReviewDeny", review);
  },

  async getEntryHistory(slug: string): Promise<EntryHistoryDto[]> {
    const response = await ApiClient.getJson<EntryHistoryDto[]>(
      "admin/EntryHistory",
      {
        slug: slug,
      },
    );

    return response.data;
  },
};
