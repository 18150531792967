<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import type { SizeVariant } from "@/components/models/SizeVariant";

export interface Props {
  type?: string;
  modelValue?: string | number;
  disabled?: boolean;
  autocomplete?: string;
  autofocus?: boolean;
  passwordrules?: string;
  minlength?: number;
  maxlength?: number;
  min?: number;
  max?: number;
  pattern?: RegExp;
  required?: boolean;
  placeholder?: string;
  area?: boolean;
  resize?: "both" | "horizontal" | "vertical" | "none";
  size?: SizeVariant;
  validator?: (value: string | number | undefined) => boolean;
  flat?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  area: false,
  size: "normal",
  validator: (v: string | number | undefined) =>
    v !== undefined && v.toString().length > 0,
  flat: false,
});

const emit = defineEmits(["update:modelValue"]);

const htmlPattern = computed(() => {
  const stringPattern = props.pattern?.toString();
  if (typeof stringPattern === "undefined") return undefined;

  return stringPattern.substring(1, stringPattern.length - 1);
});

const hadValue = ref(false);
const wasUnfocused = ref(false);

const showInvalid = computed(() => {
  if (!hadValue.value || !props.required || !wasUnfocused.value) return false;

  return !props.validator(props.modelValue);
});

function modelValueChanged(event: Event) {
  hadValue.value = true;

  emit("update:modelValue", (event.target as HTMLInputElement).value);
}

function unfocused() {
  if (!hadValue.value) return;

  wasUnfocused.value = true;
}

onMounted(() => (hadValue.value = props.modelValue !== undefined));
</script>

<template>
  <component
    :is="(area ?? false) ? 'textarea' : 'input'"
    :type="(area ?? false) ? undefined : type"
    :disabled="disabled"
    :autocomplete="autocomplete"
    :autofocus="autofocus"
    :passwordrules="passwordrules"
    :value="modelValue"
    :minlength="minlength"
    :maxlength="maxlength"
    :min="min"
    :max="max"
    :pattern="htmlPattern"
    :required="required"
    :placeholder="placeholder"
    @input="modelValueChanged"
    @focusout="unfocused"
    :class="[
      'kara-text-input',
      area ? 'kara-text-area' : null,
      size !== 'normal' ? size : '',
      showInvalid ? 'show-invalid' : null,
      flat ? 'flat' : '',
    ]"
    :style="{ resize }"
  />
</template>

<style lang="scss" scoped>
@import "@/style/_input-common.scss";
@import "@/style/_color-variations.scss";

.kara-text-input {
  @include InputCommon();
  @include ColorVariations();
  @include ColorVariationDisabled();

  &:not(&.flat) {
    @include InputShadowEffects();
  }

  &:not(&.flat) {
    @include InputShadowEffects;
  }

  background: var(--variation-background);
  color: var(--variation-color);

  &.kara-text-area {
    line-height: inherit;
  }

  &.show-invalid:invalid {
    outline: solid 1px var(--color-danger);
  }
}
</style>
