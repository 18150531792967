export default {
  sleep: (ms: number) => new Promise((resolve) => setTimeout(resolve, ms)),

  async waitUntil(
    condition: () => boolean,
    checkInterval = 100,
    timeout: number | null = null,
  ): Promise<void> {
    const startTime = Date.now();
    while (
      !condition() &&
      (timeout === null || Date.now() - startTime < timeout)
    ) {
      await this.sleep(checkInterval);
    }
  },

  debounce: (func: TimerHandler, timeout = 300) => {
    let timer: number | undefined;

    return () => {
      clearTimeout(timer);

      timer = setTimeout(func, timeout);
    };
  },
};
