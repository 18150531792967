import type { AnalyticsEventDto } from "@/dto/AnalyticsEventDto";
import ApiClient from "@/api/infrastructure/ApiClient";

export default {
  async view(dto: AnalyticsEventDto) {
    try {
      await ApiClient.head("analytics/view", dto);
    } catch {
      // do nothing
    }
  },
};
