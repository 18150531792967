import ApiResponse from "@/api/infrastructure/ApiResponse";
import NoBodyReceivedError from "@/api/infrastructure/NoBodyReceivedError";

export default class ApiResponseWithBody<T = unknown> extends ApiResponse {
  constructor(
    response: Response,
    protected body: T | null,
  ) {
    super(response);
  }

  get data(): T {
    if (this.body === null) throw new NoBodyReceivedError();

    return this.body;
  }
}
