import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import type { AuthStoreState } from "@/stores/interfaces/AuthStoreState";
import api from "@/api";
import ApiClient from "@/api/infrastructure/ApiClient";
import { useSiteSettings } from "@/stores/siteSettings";
import type AccountDto from "@/dto/AccountDto";
import toasts from "@/toasts";
import async from "@/helpers/async";

const useAuthStore = defineStore("auth", () => {
  const settings = useSiteSettings();

  const state = reactive<AuthStoreState>({
    user: null,
  });

  const fetchingSelf = ref(false);

  const isSignedIn = computed(() => state.user !== null);

  const user = computed<AccountDto>(() => state.user!);

  async function signIn(username: string, password: string): Promise<boolean> {
    const response = await api.authentication.login({ username, password });

    ApiClient.token = response.token;

    // MAYBE: save response.expiresAtUtcMillis somewhere and use it

    await fetchSelf();

    return true;
  }

  async function exchangeRegistrationToken(
    accountId: number,
    registrationToken: string,
    newPassword: string,
  ): Promise<boolean> {
    const response = await api.registration.exchangeRegistrationToken({
      accountId: accountId,
      token: registrationToken,
      newPassword: newPassword,
    });

    ApiClient.token = response.token;

    await fetchSelf();

    return true;
  }

  async function fetchSelf(force: boolean = false): Promise<AccountDto | null> {
    if (!force && state.user !== null) return state.user;
    if (ApiClient.token === null) return null;

    if (fetchingSelf.value) {
      await async.waitUntil(() => !fetchingSelf.value);

      return state.user;
    }

    fetchingSelf.value = true;

    try {
      state.user = await api.authentication.self();
    } catch {
      toasts.authTokenExpired();

      await signOut();

      return null;
    } finally {
      fetchingSelf.value = false;
    }

    const userLanguage = await api.language.get();
    if (settings.preferredLanguage != userLanguage.preferred) {
      await settings.setLanguage(userLanguage.preferred, false);
    }

    return state.user;
  }

  async function signOut(): Promise<void> {
    ApiClient.token = null;
    state.user = null;
  }

  return {
    isSignedIn,
    user,
    signIn,
    signOut,
    fetchSelf,
    exchangeRegistrationToken,
  };
});

export default useAuthStore;
