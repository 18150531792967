import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import type { LocationQuery, LocationQueryRaw } from "vue-router";
import routeParsers from "@/helpers/routeParsers";
import type { SearchFiltersState } from "@/stores/interfaces/SearchFiltersState";
import type { SearchParametersState } from "@/stores/interfaces/SearchParametersState";
import arrays from "@/helpers/arrays";
import type { SearchFilters } from "@/dto/SearchFilters";

export const useSearchStore = defineStore("search", () => {
  const defaultHitsPerPage = 10;
  const defaultPage = 0;
  const defaultQuery = "";

  const filters = reactive<SearchFiltersState>({
    federalStates: [],
    orgaTypes: [],
    segments: [],
    coops: [],
    certificates: [],
    minEmployees: null,
    maxEmployees: null,
    minBudget: null,
    maxBudget: null,
  });

  const parameters = reactive<SearchParametersState>({
    query: defaultQuery,
    hitsPerPage: defaultHitsPerPage,
    currentPage: defaultPage,
  });

  const isAnyFilterSet = computed(() => {
    return (
      filters.federalStates.length > 0 ||
      filters.orgaTypes.length > 0 ||
      filters.segments.length > 0 ||
      filters.minEmployees !== null ||
      filters.maxEmployees !== null ||
      filters.minBudget !== null ||
      filters.maxBudget !== null ||
      filters.coops.length > 0 ||
      filters.certificates.length > 0 ||
      parameters.query.length > 0
    );
  });

  function resetFilters() {
    resetQuery();
    resetFederalStates();
    resetOrgaTypes();
    resetSegments();
    resetCoops();
    resetCertificates();
    resetMinEmployees();
    resetMaxEmployees();
    resetMinBudget();
    resetMaxBudget();
  }

  function resetQuery() {
    if (parameters.query !== defaultQuery) {
      parameters.query = defaultQuery;
    }
  }

  function resetFederalStates() {
    if (filters.federalStates.length > 0) {
      filters.federalStates = [];
    }
  }

  function resetOrgaTypes() {
    if (filters.orgaTypes.length > 0) {
      filters.orgaTypes = [];
    }
  }

  function resetSegments() {
    if (filters.segments.length > 0) {
      filters.segments = [];
    }
  }

  function resetCoops() {
    if (filters.coops.length > 0) {
      filters.coops = [];
    }
  }

  function resetCertificates() {
    if (filters.certificates.length > 0) {
      filters.certificates = [];
    }
  }

  function resetMinEmployees() {
    if (filters.minEmployees !== null) {
      filters.minEmployees = null;
    }
  }

  function resetMaxEmployees() {
    if (filters.maxEmployees !== null) {
      filters.maxEmployees = null;
    }
  }

  function resetMinBudget() {
    if (filters.minBudget !== null) {
      filters.minBudget = null;
    }
  }

  function resetMaxBudget() {
    if (filters.maxBudget !== null) {
      filters.maxBudget = null;
    }
  }

  function filtersAsDto(): SearchFilters {
    return {
      segments: filters.segments.length > 0 ? filters.segments : null,
      orgaTypes: filters.orgaTypes.length > 0 ? filters.orgaTypes : null,
      minEmployeesGlobal: filters.minEmployees,
      maxEmployeesGlobal: filters.maxEmployees,
      minEmployeesSpace: null,
      maxEmployeesSpace: null,
      minBudget: filters.minBudget,
      maxBudget: filters.maxBudget,
      states: filters.federalStates.length > 0 ? filters.federalStates : null,
      certificates:
        filters.certificates.length > 0 ? filters.certificates : null,
      coops: filters.coops.length > 0 ? filters.coops : null,
    };
  }

  const query = computed(() => {
    const query = {} as LocationQueryRaw;

    if (parameters.query.length > 0) {
      query.q = parameters.query;
    }

    if (parameters.currentPage !== defaultPage) {
      const displayPage = parameters.currentPage + 1;

      query.page = displayPage.toString();
    }

    if (parameters.hitsPerPage !== defaultHitsPerPage) {
      query.hitsPerPage = parameters.hitsPerPage.toString();
    }

    if (filters.federalStates.length > 0) {
      query.federalStates = filters.federalStates.join(",");
    }

    if (filters.orgaTypes.length > 0) {
      query.orgaTypes = filters.orgaTypes.join(",");
    }

    if (filters.segments.length > 0) {
      query.segments = filters.segments.join(",");
    }

    if (filters.coops.length > 0) {
      query.coops = filters.coops.join(",");
    }

    if (filters.certificates.length > 0) {
      query.certificates = filters.certificates.join(",");
    }

    if (filters.minEmployees !== null || filters.maxEmployees !== null) {
      query.employees = routeParsers.stringifyRange(
        filters.minEmployees,
        filters.maxEmployees,
      );
    }

    if (filters.minBudget !== null || filters.maxBudget !== null) {
      query.budget = routeParsers.stringifyRange(
        filters.minBudget,
        filters.maxBudget,
      );
    }

    return query;
  });

  const readFromQuery = (urlQuery: LocationQuery): boolean => {
    if (typeof urlQuery === "undefined") {
      return false;
    }

    let somethingChanged = false;

    if (typeof urlQuery.federalStates === "string") {
      const parsedFederalStates = routeParsers.parseNumbers(
        urlQuery.federalStates,
      );

      if (!arrays.equals(parsedFederalStates, filters.federalStates)) {
        filters.federalStates = parsedFederalStates;
        somethingChanged = true;
      }
    } else if (filters.federalStates.length > 0) {
      filters.federalStates = [];
      somethingChanged = true;
    }

    if (typeof urlQuery.orgaTypes === "string") {
      const parsedOrgaTypes = routeParsers.parseNumbers(urlQuery.orgaTypes);

      if (!arrays.equals(parsedOrgaTypes, filters.orgaTypes)) {
        filters.orgaTypes = parsedOrgaTypes;
        somethingChanged = true;
      }
    } else if (filters.orgaTypes.length > 0) {
      filters.orgaTypes = [];
      somethingChanged = true;
    }

    if (typeof urlQuery.segments === "string") {
      const parsedSegments = routeParsers.parseNumbers(urlQuery.segments);

      if (!arrays.equals(parsedSegments, filters.segments)) {
        filters.segments = parsedSegments;
        somethingChanged = true;
      }
    } else if (filters.segments.length > 0) {
      filters.segments = [];
      somethingChanged = true;
    }

    if (typeof urlQuery.coops === "string") {
      const parsedCoops = routeParsers.parseNumbers(urlQuery.coops);

      if (!arrays.equals(parsedCoops, filters.coops)) {
        filters.coops = parsedCoops;
        somethingChanged = true;
      }
    }

    if (typeof urlQuery.certificates === "string") {
      const parsedCertificates = routeParsers.parseNumbers(
        urlQuery.certificates,
      );

      if (!arrays.equals(parsedCertificates, filters.certificates)) {
        filters.certificates = parsedCertificates;
        somethingChanged = true;
      }
    } else if (filters.certificates.length > 0) {
      filters.certificates = [];
      somethingChanged = true;
    }

    if (typeof urlQuery.employees === "string") {
      const parsedEmployees = routeParsers.parseRange(urlQuery.employees);

      if (parsedEmployees.min !== filters.minEmployees) {
        filters.minEmployees = parsedEmployees.min;
        somethingChanged = true;
      }

      if (parsedEmployees.max !== filters.maxEmployees) {
        filters.maxEmployees = parsedEmployees.max;
        somethingChanged = true;
      }
    } else {
      if (filters.minEmployees !== null) {
        filters.minEmployees = null;
        somethingChanged = true;
      }

      if (filters.maxEmployees !== null) {
        filters.maxEmployees = null;
        somethingChanged = true;
      }
    }

    if (typeof urlQuery.budget === "string") {
      const parsedBudget = routeParsers.parseRange(urlQuery.budget);

      if (parsedBudget.min !== filters.minBudget) {
        filters.minBudget = parsedBudget.min;
        somethingChanged = true;
      }

      if (parsedBudget.max !== filters.maxBudget) {
        filters.maxBudget = parsedBudget.max;
        somethingChanged = true;
      }
    } else {
      if (filters.minBudget !== null) {
        filters.minBudget = null;
        somethingChanged = true;
      }

      if (filters.maxBudget !== null) {
        filters.maxBudget = null;
        somethingChanged = true;
      }
    }

    // MIND: update filters before setting parameters as the filter watcher can override them

    if (typeof urlQuery.q === "string" && urlQuery.q.length > 0) {
      if (urlQuery.q !== parameters.query) {
        parameters.query = urlQuery.q;
        somethingChanged = true;
      }
    } else if (parameters.query !== defaultQuery) {
      parameters.query = defaultQuery;
      somethingChanged = true;
    }

    if (typeof urlQuery.page === "string" && urlQuery.page.length > 0) {
      const displayPage = parseInt(urlQuery.page, 10);
      const currentPage = displayPage - 1;

      if (parameters.currentPage !== currentPage) {
        parameters.currentPage = currentPage;
        somethingChanged = true;
      }
    } else if (parameters.currentPage !== defaultPage) {
      parameters.currentPage = defaultPage;
      somethingChanged = true;
    }

    if (
      typeof urlQuery.hitsPerPage === "string" &&
      urlQuery.hitsPerPage.length > 0
    ) {
      const hitsPerPage = parseInt(urlQuery.hitsPerPage, 10);

      if (parameters.hitsPerPage !== hitsPerPage) {
        parameters.hitsPerPage = hitsPerPage;
        somethingChanged = true;
      }
    } else if (parameters.hitsPerPage !== defaultHitsPerPage) {
      parameters.hitsPerPage = defaultHitsPerPage;
      somethingChanged = true;
    }

    return somethingChanged;
  };

  return {
    filters,
    parameters,
    query,
    readFromQuery,
    defaultHitsPerPage,
    resetFilters,
    isAnyFilterSet,
    filtersAsDto,
    resetFederalStates,
    resetOrgaTypes,
    resetSegments,
    resetCoops,
    resetCertificates,
  };
});
