import type {
  LocationQueryRaw,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
} from "vue-router";
import type { RouteRecordName } from "vue-router";

const noReturnRoutes = [
  "home",
  "login",
  "logout",
  "forgot-password",
  "not-found",
] as RouteRecordName[];

export default {
  async handleReturn(
    route: RouteLocationNormalizedLoaded,
  ): Promise<RouteLocationRaw | null> {
    if (typeof route.query.return !== "string") return null;

    const decoded = decodeURIComponent(route.query.return);
    if (!decoded.startsWith("/")) return null;

    const url = new URL(
      decoded,
      `${window.location.protocol}//${window.location.host}`,
    );
    const query = {} as Record<string, string>;
    for (const searchParam of url.searchParams) {
      query[searchParam[0]] = searchParam[1];
    }

    return {
      path: url.pathname,
      query: query,
    } as RouteLocationRaw;
  },

  calculateReturn(route: RouteLocationNormalizedLoaded): LocationQueryRaw {
    const query: LocationQueryRaw = {};
    if (!noReturnRoutes.includes(route.name ?? "not-found")) {
      query["return"] = encodeURIComponent(route.fullPath);
    }

    return query;
  },
};
