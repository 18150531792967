import ApiClient from "@/api/infrastructure/ApiClient";
import type I18NLanguage from "@/stores/interfaces/I18NLanguage";
import { LangEn } from "@/stores/interfaces/I18NLanguage";
import type GetLanguageDto from "@/dto/GetLanguageDto";

export default {
  async set(preferred: I18NLanguage): Promise<boolean> {
    try {
      const response = await ApiClient.patch("language", { preferred });

      return response.ok;
    } catch {
      return false;
    }
  },

  async get(): Promise<GetLanguageDto> {
    try {
      const response = await ApiClient.getJson<GetLanguageDto>("language");

      return response.data;
    } catch {
      return {
        preferred: LangEn,
      };
    }
  },
};
